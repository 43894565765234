<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">页面参数</div>
            <el-form-item label="申请页顶部图片" >
              <y_upload_img v-model="form.apply_page_banner"></y_upload_img>
            </el-form-item>
            <el-form-item label="代理服务协议">
              <br>
              <y_editor v-model="form.service_agreement" eid="service"></y_editor>
            </el-form-item>
            <el-form-item label="代理权益规则">
              <br>
              <y_editor v-model="form.rights_rule" eid="rights-rule"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">规则配置</div>
            <el-form-item label="服务费用">
              <el-input-number v-model="form.apply_fee"></el-input-number>元,
              有效期<el-input-number v-model="form.period_of_validity"></el-input-number>天，
              可邀请<el-input-number v-model="form.invite_limit"></el-input-number>个商家
            </el-form-item>
            <el-divider content-position="left">代理权益</el-divider>
              <div class="y-desc">代理可享受 <strong>免单卡直推奖励权益</strong></div>
              <div class="y-desc">不与其它身份的免单卡直推奖励权益叠加</div>
            <el-divider content-position="left">代理老板专享权益</el-divider>
            <el-form-item label="权益说明">
              <div class="y-desc">代理同时为商家老板时</div>
              <div class="y-desc">代理直推免单卡订单得此权益金额</div>
              <div class="y-desc">员工邀请关系链下新免单卡订单 代理获得此权益金额</div>
              <div class="y-desc">与商家规则配置中的商家老板专享权益叠加</div>
            </el-form-item>
            <el-form-item label="奖励金额">
              <el-input-number v-model="form.rights_boss_award"></el-input-number>元
            </el-form-item>
            <el-divider content-position="left">代理直推商家权益</el-divider>
            <el-form-item label="权益说明">
              <div class="y-desc">代理直推邀请的商家</div>
              <div class="y-desc">老板、员工直推免单卡订单 代理获得此权益金额</div>
            </el-form-item>
            <el-form-item label="奖励金额">
              <el-input-number v-model="form.rights_invite_merchant_award"></el-input-number>元
            </el-form-item>
            <el-divider content-position="left">代理直推代理权益</el-divider>
            <el-form-item label="直推奖励金额">
              <el-input-number v-model="form.rights_invite_agent_award"></el-input-number>元
            </el-form-item>
            <el-form-item label="平级奖励说明">
              <div class="y-desc">代理直推下级代理</div>
              <div class="y-desc">下级代理本人 及 下级代理邀请的商家 老板、员工直推免单卡订单 代理获得此权益金额</div>
            </el-form-item>
            <el-form-item label="平级奖励金额">
              <el-input-number v-model="form.rights_invite_agent_ping_ji_award"></el-input-number>元
            </el-form-item>
            <el-form-item label="关闭奖励">
              <el-switch v-model="form.award_close"></el-switch>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {
    y_editor,
    y_upload_img,
  },
  data() {
    return {
      form: {
        apply_page_banner:"",
        service_agreement:"",
        rights_rule:"",
        apply_fee:0,
        period_of_validity:0,
        rights_boss_award:0,
        invite_limit:0,
        rights_invite_merchant_award:0,
        rights_invite_agent_award:0,
        rights_invite_agent_ping_ji_award:0,

        award_close:false,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.privilegeCard.ruleAgent().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.shop.plugin.privilegeCard.ruleAgentEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>